<template>
  <div class="container-fluid">
    <Breadcrumb
      :customBreadcrumbList="[
        { name: 'Home' },
        { name: 'EnrollmentList' },
        { name: 'Arrange' },
        {
          text: selectedCourseSession
            ? helper.displayI18nText($i18n.locale, selectedCourseSession.title)
            : 'All'
        }
      ]"
    />
    <Heading :content="`${$t('arrange.title')} - ${selectTitle}`">
      <el-button
        slot="button"
        plain
        type="success"
        size="small"
        @click="$router.push({ name: 'privateLessons' })"
        >查看家教課 <i class="fa fa-laptop-house"></i>
      </el-button>
    </Heading>
    <hr class="separate-line" />
    <div class="mb-3">
      <Heading heading="h4" content="篩選梯次">
        <template slot="button">
          <el-button @click="createCourseSession" type="primary" size="mini">
            <i class="el-icon-plus"></i>
            新增梯次
          </el-button>
          <router-link class="ml-2" :to="{ name: 'courseSessionsList' }">
            <el-button type="warning" size="mini">
              <i class="fas fa-edit" />
              編輯梯次
            </el-button>
          </router-link>
        </template>
      </Heading>
    </div>
    <SessionList
      @onSessionChanged="filterListBySession"
      type="1"
      :activeSessionId="sessionId"
      :showStudentLeft="true"
    />

    <hr />

    <div class="row mb-4">
      <div class="col-12 col-md-8 order-md-2">
        <div class="ivy-schedule">
          <h4 v-if="showLoadingMsg">載入課程時間表中...</h4>
          <table>
            <tr>
              <th v-if="!showLoadingMsg">
                <span
                  v-if="!showLoadingMsg && placementList.classes.length == 0"
                  >本梯次尚無班級資料</span
                >
              </th>
              <th v-for="(teacher, index) in classTeachers" :key="index">
                <a v-if="teacher" type="primary" :href="'/user/' + teacher.id">
                  {{ teacher.first_name }} {{ teacher.last_name }}
                </a>
                <a v-else>
                  [待定] 老師
                </a>
              </th>
            </tr>
            <tr
              v-for="(time_node, index) in placementList.time_from_nodes"
              :key="index"
              style="height: 100%;"
            >
              <th>
                {{ instant.formatTime(time_node) }}
              </th>
              <td
                v-for="(class_content, index) in placementList.classes"
                :key="index"
                style="padding:0; margin:0; vertical-align: top;"
              >
                <div style="height: 100%; display:block;">
                  <!-- <div
                    v-if="
                      class_content.teacher_user_id == teacher.id &&
                        class_content.time_from_node == time_node
                    "
                    style="height: 100%; display:block; background-color:white;"
                  > -->
                  <div
                    v-if="class_content.time_from == time_node"
                    style="height: 100%; display:block;"
                  >
                    <div v-if="class_content != null" style="padding: 10px;">
                      <div>
                        <!-- <el-form>
                          <el-form-item :label="$t('arrange.class')">
                            <router-link
                              :to="`/session-classes/${class_content.id}`"
                            >
                              <i class="fas fa-eye" />
                              <b class="text-success">
                                {{ class_content.title }}
                              </b>
                            </router-link>
                          </el-form-item>
                        </el-form> -->
                        <ul class="ivy-class-info">
                          <li>
                            <span> {{ $t("arrange.class") }}: </span>
                            <router-link
                              :to="`/session-classes/${class_content.id}`"
                            >
                              <i class="fas fa-eye" />
                              <b class="text-success">
                                {{ class_content.title }}
                              </b>
                            </router-link>
                          </li>
                          <li>
                            <span> {{ $t("arrange.courseSession") }}: </span>
                            <span v-if="class_content.course_session != null">
                              <span
                                v-if="class_content.course_session.short_name"
                              >
                                {{ class_content.course_session.short_name }}
                              </span>
                              <span v-else>
                                {{
                                  helper.displayI18nText(
                                    $i18n.locale,
                                    class_content.course_session.title
                                  )
                                }}
                              </span>
                            </span>
                          </li>
                          <li>
                            <span> {{ $t("arrange.date") }}: </span>
                            <span>
                              {{
                                moment(class_content.started_on).format("M/D")
                              }}～{{
                                moment(class_content.ended_on).format("M/D")
                              }}
                            </span>
                          </li>
                          <li>
                            <span> {{ $t("arrange.time") }}: </span>
                            <span>
                              {{
                                instant.formatTime(class_content.time_from)
                              }}～{{
                                instant.formatTime(class_content.time_to)
                              }}
                            </span>
                          </li>
                          <li>
                            <span> {{ $t("arrange.address") }}: </span>
                            <span>
                              {{ class_content.address }}
                            </span>
                          </li>
                          <li>
                            <span> {{ $t("arrange.classroom") }}: </span>
                            <span v-if="class_content.classroom != null">
                              {{ class_content.classroom.name }}
                            </span>
                          </li>
                          <li>
                            <span> {{ $t("arrange.studyRoom") }}: </span>
                            <span v-if="class_content.study_room != null">
                              {{ class_content.study_room.name }}
                            </span>
                          </li>
                          <li>
                            <span> {{ $t("arrange.scoreRange") }}: </span>
                            <span v-if="class_content.min_sat_score != null">
                              SAT: {{ class_content.min_sat_score }} ~
                              {{ class_content.max_sat_score }}
                            </span>
                            <span v-if="class_content.min_act_score != null">
                              ACT: {{ class_content.min_act_score }} ~
                              {{ class_content.max_act_score }}
                            </span>
                          </li>
                          <li>
                            <span> {{ $t("arrange.ta") }}: </span>
                            <span v-if="class_content.assistant != null">
                              <el-link
                                type="primary"
                                :href="
                                  '/user/' + class_content.assistant_user_id
                                "
                              >
                                {{ class_content.assistant.first_name }}
                                {{ class_content.assistant.last_name }}
                              </el-link>
                            </span>
                          </li>
                        </ul>

                        <el-divider></el-divider>

                        <div class="font-weight-bold">
                          Students:
                        </div>
                        <div
                          v-for="(ticket,
                          index) in class_content.student_class_tickets"
                          :key="index"
                        >
                          <el-dropdown
                            style="height:34px;width:100%"
                            trigger="click"
                            placement="bottom-start"
                            @command="
                              command => handlePlacementActions(command, ticket)
                            "
                          >
                            <div
                              class="el-dropdown-link"
                              style="line-height:34px"
                            >
                              {{
                                userMethods.displayName(
                                  ticket.student.first_name,
                                  ticket.student.last_name
                                )
                              }}

                              <span
                                v-if="ticket.score && ticket.score.sat_score"
                              >
                                <!-- <a
                                  :href="
                                    '/sat/result?user_exam_id=' +
                                      ticket.score.sat_score.user_exam_id
                                  "
                                  target="_blank"
                                  v-if="ticket.score.sat_score !== null"
                                > -->
                                <b>
                                  SAT:
                                  {{ ticket.score.sat_score.total_score }}</b
                                >
                                ({{
                                  ticket.score.sat_score.section_english_score
                                }}
                                /
                                {{ ticket.score.sat_score.section_math_score }})
                                <!-- </a> -->
                              </span>

                              <span
                                v-if="ticket.score && ticket.score.act_score"
                              >
                                <!-- <a
                                  :href="
                                    '/act/result?user_exam_id=' +
                                      ticket.score.act_score.user_exam_id
                                  "
                                  target="_blank"
                                  v-if="ticket.score.act_score !== null"
                                > -->
                                <b>
                                  ACT:
                                  {{ ticket.score.act_score.total_score }}</b
                                >
                                ({{
                                  ticket.score.act_score.section_english_score
                                }}
                                /
                                {{ ticket.score.act_score.section_math_score }}
                                /
                                {{ ticket.score.act_score.section_math_score }}
                                /
                                {{ ticket.score.act_score.section_math_score }}
                                )
                                <!-- </a> -->
                              </span>

                              <el-tooltip
                                v-if="
                                  ticket.enrolled_session != null &&
                                    ticket.enrolled_session.need_pay_first ==
                                      1 &&
                                    ticket.enrolled_session.payment_status != 1
                                "
                                class="item"
                                effect="dark"
                                content="預付未付清"
                                placement="top"
                              >
                                <el-button
                                  type="text"
                                  icon="el-icon-warning"
                                  style="color:#f56c6c;"
                                ></el-button>
                              </el-tooltip>

                              <i class="el-icon-arrow-down el-icon--right"></i>

                              <br />
                            </div>
                            <el-dropdown-menu slot="dropdown">
                              <el-dropdown-item disabled="true">
                                {{
                                  userMethods.displayName(
                                    ticket.student.first_name,
                                    ticket.student.last_name
                                  )
                                }}
                              </el-dropdown-item>
                              <!-- 查看報名表 -->
                              <el-dropdown-item
                                divided="true"
                                command="checkEnrolledSession"
                              >
                                {{ actionList[0] }}
                              </el-dropdown-item>
                              <!-- 查看學生 -->
                              <el-dropdown-item command="checkStudentProfile">
                                {{ actionList[1] }}
                              </el-dropdown-item>
                              <!-- 換班 -->
                              <el-dropdown-item command="showChangeClassDialog">
                                {{ actionList[3] }}
                              </el-dropdown-item>
                              <!-- 移除 -->
                              <el-dropdown-item command="removeFromClass">
                                {{ actionList[4] }}
                              </el-dropdown-item>
                            </el-dropdown-menu>
                          </el-dropdown>
                        </div>
                      </div>
                    </div>
                    <div
                      v-else
                      style="background-color:#b3d9c3;  height:100%;"
                    ></div>
                  </div>
                </div>
                <!--Class /-->
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="col-12 col-md-4 order-md-1">
        <el-card>
          <div slot="header" class="clearfix">
            <Heading heading="h5" :content="$t('arrange.studentListTitle')">
              <el-button
                slot="button"
                @click="quickAdd"
                type="primary"
                size="mini"
              >
                <i class="el-icon-plus"></i>
                給學生創建報名表
              </el-button>
            </Heading>
          </div>
          <div>
            <el-table
              :data="studentList"
              style="width: 100%"
              height="600px"
              size="mini"
              border
            >
              <el-table-column label="Student">
                <template slot-scope="scope">
                  <b>
                    {{
                      userMethods.displayName(
                        scope.row.first_name,
                        scope.row.last_name
                      )
                    }}
                  </b>
                </template>
              </el-table-column>
              <el-table-column label="Title" width="195">
                <template slot-scope="scope">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="
                      scope.row.short_name
                        ? scope.row.short_name
                        : helper.displayI18nText($i18n.locale, scope.row.title)
                    "
                    placement="top"
                  >
                    <el-tag>
                      <span
                        style="cursor: pointer;display:inline-block; width: 150px;overflow: hidden;text-overflow: ellipsis;"
                      >
                        <span v-if="scope.row.short_name">
                          {{ scope.row.short_name }}</span
                        >
                        <span v-else>
                          {{
                            helper.displayI18nText(
                              $i18n.locale,
                              scope.row.title
                            )
                          }}
                        </span>
                      </span>
                    </el-tag>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column label="Score">
                <template slot-scope="scope">
                  <span v-if="scope.row.score && scope.row.score.sat_score">
                    <b> SAT: {{ scope.row.score.sat_score.total_score }}</b> ({{
                      scope.row.score.sat_score.section_english_score
                    }}
                    / {{ scope.row.score.sat_score.section_math_score }})
                  </span>

                  <span
                    v-if="scope.row.score && scope.row.score.act_score !== null"
                  >
                    <b> ACT: {{ scope.row.score.act_score.total_score }}</b> ({{
                      scope.row.score.act_score.section_english_score
                    }}
                    / {{ scope.row.score.act_score.section_math_score }} /
                    {{ scope.row.score.act_score.section_math_score }} /
                    {{ scope.row.score.act_score.section_math_score }} )
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="Status" width="60">
                <template slot-scope="scope">
                  <div class="text-center">
                    <el-tooltip
                      v-if="
                        scope.row.need_pay_first == 1 &&
                          scope.row.payment_status != 1
                      "
                      class="item"
                      effect="dark"
                      content="預付未付清"
                      placement="top"
                    >
                      <el-button
                        type="text"
                        icon="el-icon-warning"
                        style="color:#f56c6c;padding: 10px 0;"
                      ></el-button>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="Actions" fixed="right">
                <template slot-scope="scope">
                  <el-dropdown
                    @command="
                      command => handlePlacementActions(command, scope.row)
                    "
                    placement="bottom"
                  >
                    <el-button plain type="success" size="mini">
                      <i class="el-icon-arrow-down"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item disabled="true">
                        <b>{{
                          userMethods.displayName(
                            scope.row.first_name,
                            scope.row.last_name
                          )
                        }}</b>
                      </el-dropdown-item>
                      <!-- 查看報名表 -->
                      <el-dropdown-item
                        divided="true"
                        command="checkEnrolledSession"
                      >
                        {{ actionList[0] }}
                      </el-dropdown-item>
                      <!-- 查看學生 -->
                      <el-dropdown-item command="checkStudentProfile">
                        {{ actionList[1] }}
                      </el-dropdown-item>
                      <!-- 分班 -->
                      <el-dropdown-item command="showPlacementDialog">
                        {{ actionList[2] }}
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </el-table-column>
            </el-table>
          </div>

          <template v-if="false">
            <el-dropdown
              v-for="student in studentList"
              style="height:34px;width:100%"
              :key="student.id"
              @command="command => handlePlacementActions(command, student)"
              placement="bottom"
            >
              <div class="el-dropdown-link" style="line-height:34px">
                <b>{{
                  userMethods.displayName(student.first_name, student.last_name)
                }}</b>
                <span v-if="student.short_name">
                  ({{ student.short_name }})</span
                >
                <span v-else>
                  ({{
                    helper.displayI18nText($i18n.locale, student.title)
                  }})</span
                >
                <span v-if="student.score && student.score.sat_score">
                  <!-- <a
                    :href="
                      '/sat/result?user_exam_id=' +
                        student.score.sat_score.user_exam_id
                    "
                    target="_blank"
                    v-if="student.score.sat_score !== null"
                  > -->
                  <b> SAT: {{ student.score.sat_score.total_score }}</b> ({{
                    student.score.sat_score.section_english_score
                  }}
                  / {{ student.score.sat_score.section_math_score }})
                  <!-- </a> -->
                </span>

                <span v-if="student.score && student.score.act_score !== null">
                  <!-- <a
                    :href="
                      '/act/result?user_exam_id=' +
                        student.score.act_score.user_exam_id
                    "
                    target="_blank"
                    v-if="student.score.act_score !== null"
                  > -->
                  <b> ACT: {{ student.score.act_score.total_score }}</b> ({{
                    student.score.act_score.section_english_score
                  }}
                  / {{ student.score.act_score.section_math_score }} /
                  {{ student.score.act_score.section_math_score }} /
                  {{ student.score.act_score.section_math_score }} )
                  <!-- </a> -->
                </span>

                &nbsp;
                <el-tooltip
                  v-if="
                    student.need_pay_first == 1 && student.payment_status != 1
                  "
                  class="item"
                  effect="dark"
                  content="預付未付清"
                  placement="top"
                >
                  <el-button
                    type="text"
                    icon="el-icon-warning"
                    style="color:#f56c6c;padding: 10px 0;"
                  ></el-button>
                </el-tooltip>
                <el-button plain type="success" size="mini">
                  <i class="el-icon-arrow-down"></i>
                </el-button>

                <!--span>（考試：科目一／科目二）</span-->
              </div>

              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item disabled="true">
                  <b>{{
                    userMethods.displayName(
                      student.first_name,
                      student.last_name
                    )
                  }}</b>
                </el-dropdown-item>
                <!-- 查看報名表 -->
                <el-dropdown-item divided="true" command="checkEnrolledSession">
                  {{ actionList[0] }}
                </el-dropdown-item>
                <!-- 查看學生 -->
                <el-dropdown-item command="checkStudentProfile">
                  {{ actionList[1] }}
                </el-dropdown-item>
                <!-- 分班 -->
                <el-dropdown-item command="showPlacementDialog">
                  {{ actionList[2] }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-card>
      </div>
    </div>

    <el-dialog
      :title="
        `給 ${userMethods.displayName(
          placementForm.studentClassTicket.first_name,
          placementForm.studentClassTicket.last_name
        )} 分班`
      "
      :visible.sync="dialogFormVisible"
    >
      <!-- <Heading
        :content="
          `梯次: ${helper.displayI18nText(
            $i18n.locale,
            placementForm.studentClassTicket.title
          )}`
        "
        heading="h6"
      ></Heading>
      <hr /> -->
      <el-form :model="placementForm" width="50%">
        <el-form-item label="梯次" label-width="70px">
          {{
            helper.displayI18nText(
              $i18n.locale,
              placementForm.studentClassTicket.title
            )
          }}
        </el-form-item>
        <el-form-item label="班級" label-width="70px">
          <el-select
            v-model="placementForm.studentClassTicket.class_id"
            placeholder="請選擇班級"
            style="width:100%"
            popper-class="select-popper"
            :popper-append-to-body="false"
          >
            <el-option
              v-for="(class_content, index) in placementList.classes"
              :key="index"
              :label="class_content.title"
              :value="class_content.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">Cancel</el-button>
        <el-button type="primary" @click="placeStudent(placementForm)"
          >Submit</el-button
        >
      </div>
    </el-dialog>

    <el-dialog title="報名課程" :visible.sync="quickAddDialogFormVisible">
      <el-form :model="quickEnrollmentForm">
        <el-row>
          <el-col :span="24">
            <el-alert class="mb-3" :closable="false" type="warning">
              <h6 class="mb-0">
                找不到舊生資料嗎？請<router-link
                  :to="{
                    name: 'QuickEnrollNew'
                  }"
                  >填寫完整報名表</router-link
                >
              </h6>
            </el-alert>
          </el-col>
          <el-col :span="24">
            <el-form-item label="學生" :label-width="formLabelWidth">
              <el-select
                v-model="selectedStudents"
                multiple
                :multiple-limit="1"
                filterable
                style="width:100%"
                popper-class="select-popper"
                :popper-append-to-body="false"
                name="student"
                :filter-method="filterStudent"
              >
                <el-option
                  v-for="(student,
                  index) in quickEnrollmentForm.searchStudentsList"
                  :key="index"
                  :label="
                    student.first_name +
                      ' ' +
                      student.last_name +
                      ' ( ' +
                      student.email +
                      ' ) '
                  "
                  :value="student.user_id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="梯次" :label-width="formLabelWidth">
          <el-select
            v-model="quickEnrollmentForm.course_session_id"
            placeholder="梯次"
            required
            style="width:100%"
            popper-class="select-popper"
            :popper-append-to-body="false"
          >
            <el-option
              v-for="course_session in courseSessions"
              :key="course_session.id"
              :label="
                helper.displayI18nText($i18n.locale, course_session.title)
              "
              :value="course_session.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="quickAddDialogFormVisible = false">取消</el-button>
        <el-button
          type="primary"
          @click="quickEnrollStudent(quickEnrollmentForm)"
          >報名</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState } from "vuex";
/*eslint-enable */

import moment from "moment";
import { instant, helper, user } from "@ivy-way/material";
import SessionList from "@/components/course/SessionList";
import Breadcrumb from "@/components/Breadcrumb";

import arrangeApi from "@/apis/arrange";
import courseApi from "@/apis/course";
import userApi from "@/apis/user";

export default {
  metaInfo() {
    return {
      title:
        this.$t("arrange.title") +
        " - " +
        this.selectTitle +
        " - Ivy-Way Academy"
    };
  },

  components: {
    Breadcrumb,
    SessionList
    // Class
  },

  props: [],
  data() {
    return {
      moment: moment,
      studentList: [],
      placementList: [],
      dialogFormVisible: false,
      placementForm: {
        studentClassTicket: {
          first_name: "",
          last_name: "",
          title: ""
        },
        class_id: 1,
        classesList: []
      },
      formLabelWidth: "120px",
      quickAddDialogFormVisible: false,
      quickEnrollmentForm: {
        searchStudentsList: [],
        student_user_id: null,
        course_session_id: null
      },
      selectedStudents: [],
      allStudents: [],
      courseSessions: [],
      selectedCourseSession: null,
      showLoadingMsg: true,
      sessionId: null,
      hasTest: false,
      testType: "sat"
    };
  },
  computed: {
    ...mapState("user", ["token", "lang"]),
    actionList() {
      return this.$t("arrange.actionList");
    },
    classTeachers() {
      let teachers = [];
      if (
        this.placementList &&
        this.placementList.classes &&
        this.placementList.classes.length > 0
      ) {
        this.placementList.classes.forEach(item => {
          teachers.push(item.teacher);
        });
      }
      return teachers;
    },
    selectTitle() {
      let selectTitle = "";
      if (this.selectedCourseSession === null) {
        selectTitle = "All";
      } else {
        selectTitle = this.helper.displayI18nText(
          this.$i18n.locale,
          this.selectedCourseSession.title
        );
      }
      return selectTitle;
    },
    helper() {
      return helper;
    },
    instant() {
      return instant;
    },
    userMethods() {
      return user;
    }
  },
  watch: {
    selectedStudents(val) {
      if (val && val.length > 0) {
        this.quickEnrollmentForm.student_user_id = val[0];
      }
    }
  },

  mounted() {
    if(this.$route.query.sessionId){
      this.sessionId = this.$route.query.sessionId;
    }
    this.getStudentList();
    this.getPlacementList();
    this.getCourseSessions();
  },

  methods: {
    filterStudent(query) {
      let arr = this.allStudents.filter(item => {
        if (
          item.first_name &&
          item.first_name.toLowerCase().includes(query.toLowerCase())
        ) {
          return true;
        }
        if (
          item.last_name &&
          item.last_name.toLowerCase().includes(query.toLowerCase())
        ) {
          return true;
        }
        if (
          `${item.first_name} ${item.last_name}`
            .toLowerCase()
            .includes(query.toLowerCase())
        ) {
          return true;
        }
        if (
          `${item.last_name}${item.first_name}`
            .toLowerCase()
            .includes(query.toLowerCase())
        ) {
          return true;
        }
        return false;
      });
      if (arr.length > 30) {
        arr = arr.slice(0, 30);
      }
      const chosen = this.getChosenItemsArr();
      // 清空之前的选项
      this.quickEnrollmentForm.searchStudentsList.splice(
        0,
        this.quickEnrollmentForm.searchStudentsList.length
      );
      const result = [...arr, ...chosen.filter(item => !arr.includes(item))];
      this.quickEnrollmentForm.searchStudentsList.push(...result);
    },
    getChosenItemsArr() {
      // 获取已被选中的人员
      const items = [];
      for (let i = 0; i < this.allStudents.length; i++) {
        if (
          this.selectedStudents.indexOf(this.allStudents[i].id) >= 0 &&
          items.indexOf(this.allStudents[i]) < 0
        ) {
          items.push(this.allStudents[i]);
        }
      }
      return items;
    },
    async quickAdd() {
      const students = await userApi.searchStudents();
      this.allStudents = JSON.parse(JSON.stringify(students));
      this.quickAddDialogFormVisible = true;
    },
    filterListBySession(sessionId) {
      this.$router.replace({ query: { sessionId: sessionId } });
    },

    async getCourseSessions() {
      let param = {
        created_after: "2020-01-01 00:00:00",
        type: 1,
        is_visible: 1
      };
      let sessionRes = await courseApi.fetchSessions(param);
      this.courseSessions = sessionRes.course_sessions;
    },

    async getSelectedCourseSession(sessionId) {
      let sessionRes = await courseApi.fetchSession(sessionId);
      this.selectedCourseSession = sessionRes.course_session;
    },

    createCourseSession() {
      this.$router.push({
        name: "courseSessionsCreate"
      });
    },

    async getStudentList() {
      let params;
      if (this.sessionId) {
        params = {
          course_session_id: this.sessionId
        };
      }

      const listRes = await arrangeApi.fetchStudentList(params);

      const { student_class_tickets } = listRes;

      this.studentList = student_class_tickets;
    },
    async getPlacementList() {
      this.showLoadingMsg = true;
      let params;
      if (this.sessionId) {
        params = {
          course_session_id: this.sessionId
        };
      }

      const placementList = await arrangeApi.fetchPlacementList(params);

      this.placementList = placementList;
      this.showLoadingMsg = false;
    },
    async getClasses(course_session_id) {
      let queryParams = {
        course_session_id: course_session_id
      };
      let sessionClassesRes = await courseApi.fetchSessionClasses(queryParams);
      this.placementForm.classesList = sessionClassesRes.session_classes;
    },
    async searchStudents(search_text) {
      let queryParams = {
        search_text: search_text
      };
      this.quickEnrollmentForm.searchStudentsList = await userApi.searchStudents(
        queryParams
      );
    },

    handlePlacementActions(command, studentClassTicket) {
      // 查看學生資料
      if (command == "checkStudentProfile") {
        this.$router.push({
          name: "ProfileForAdmin",
          params: { id: studentClassTicket.student_user_id }
        });
      }

      // 查看報名表
      if (command == "checkEnrolledSession") {
        this.$router.push({
          name: "EnrollmentDetail",
          params: { id: studentClassTicket.enrolled_session_id }
        });
      }

      // 顯示分班的dialog
      if (command == "showPlacementDialog") {
        this.placementForm.studentClassTicket = studentClassTicket;
        this.placementForm.classesList = this.getClasses(
          studentClassTicket.included_course_session_id
        );
        this.dialogFormVisible = true;
      }

      // 換班（跟分班的dialog相同，但是組的內容要吃的節點結構不太一樣）
      if (command == "showChangeClassDialog") {
        this.placementForm.studentClassTicket = studentClassTicket;
        if (studentClassTicket.student != null) {
          this.placementForm.studentClassTicket.first_name =
            studentClassTicket.student.first_name;
          this.placementForm.studentClassTicket.last_name =
            studentClassTicket.student.last_name;
        }
        if (
          studentClassTicket.class_ticket != null &&
          studentClassTicket.class_ticket.included_course_session != null
        ) {
          this.placementForm.studentClassTicket.title =
            studentClassTicket.class_ticket.included_course_session.title;
          this.placementForm.classesList = this.getClasses(
            studentClassTicket.class_ticket.included_course_session_id
          );
        } else {
          this.placementForm.studentClassTicket.title =
            "本分班卷未限制梯次或未找到梯次";
          this.placementForm.classesList = this.getClasses(null);
        }

        this.dialogFormVisible = true;
      }

      // 從班級中移除
      if (command == "removeFromClass") {
        this.placementForm.studentClassTicket = studentClassTicket;
        this.placementForm.studentClassTicket.class_id = null;
        this.placeStudent();
      }
    },
    async placeStudent() {
      await arrangeApi.postStudentClassTicket(
        this.placementForm.studentClassTicket.id,
        { class_id: this.placementForm.studentClassTicket.class_id }
      );
      this.$message({
        showClose: true,
        message: this.$t("message.update_success"),
        type: "success"
      });
      this.dialogFormVisible = false;
      await this.getStudentList();
      await this.getPlacementList();
      await this.getCourseSessions();
    },

    async quickEnrollStudent(quickEnrollmentForm) {
      await arrangeApi.quickEnrollStudent(quickEnrollmentForm);
      this.$message({
        showClose: true,
        message: this.$t("message.update_success"),
        type: "success"
      });
      this.quickAddDialogFormVisible = false;
      // await this.$router.go(0);
      await this.getStudentList();
      await this.getPlacementList();
      await this.getCourseSessions();
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .select-popper {
  z-index: 9999 !important;
  top: auto !important;
  left: auto !important;
}
.ivy-class-info {
  height: 260px;
  overflow: auto;
  margin-bottom: 0;
}
.ivy-class-info * {
  font-size: 13px;
}
.ivy-schedule {
  overflow: auto;

  table {
    min-width: 100%;
  }

  tr:first-child th:not(:first-child) {
    min-width: 235px;
  }

  td {
    border: 1px solid #000;
    padding: 15px;
  }

  td:empty {
    background-color: #000;
  }

  th {
    border: 1px solid #000;
    padding: 15px;
  }

  th {
    text-align: center;

    &:first-child {
      width: 80px;
    }
  }
}

.ivy-class-info {
  list-style: none;
  padding-left: 0;

  span {
    display: inline-block;

    &:first-child {
      padding-right: 5px;
      font-weight: bolder;
    }
  }
}
</style>
