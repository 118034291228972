<template>
  <el-radio-group
    @change="$emit('onSessionChanged', sessionId)"
    v-model="sessionId"
    size="small"
    class="mb-3"
  >
    <el-radio-button :label="0">
      {{ $t("enrollment.list.all") }}
      <el-tooltip
        v-if="showStudentLeft"
        class="item"
        effect="dark"
        :content="'本梯次有 ' + studentsLeft + ' 名學生未分班'"
        placement="top"
      >
        <el-badge :value="studentsLeft" :max="99" />
      </el-tooltip>
    </el-radio-button>

    <el-radio-button
      v-for="session in sessionList"
      :key="session.id"
      :label="session.id"
    >
      <span v-if="session.short_name">{{session.short_name}}</span>
      <span v-else>{{ helper.displayI18nText($i18n.locale, session.title) }}</span>
      <el-tooltip
        v-if="showStudentLeft && session.student_left > 0"
        class="item"
        effect="dark"
        :content="'本梯次有 ' + session.student_left + ' 名學生未分班'"
        placement="top"
      >
        <el-badge :value="session.student_left" :max="99" />
      </el-tooltip>
      <el-tooltip
        v-if="showStudentEnrollCount && session.student_user_count > 0"
        class="item"
        effect="dark"
        :content="'本梯次有 ' + session.student_user_count + ' 名學生報名'"
        placement="top"
      >
        <el-badge :value="session.student_user_count" :max="99" />
      </el-tooltip>
    </el-radio-button>
  </el-radio-group>
</template>

<script>
import { helper } from "@ivy-way/material";
import courseApi from "@/apis/course";

export default {
  components: {},

  props: [
    "showStudentLeft",
    "showStudentEnrollCount",
    "type",
    "activeSessionId"
  ],
  data() {
    return {
      sessionId: 0,

      sessionList: [],
      studentsLeft: 0,
      is_visible: 1
    };
  },
  computed: {
    helper() {
      return helper;
    }
  },
  watch: {
    activeSessionId() {
      if (this.activeSessionId) this.sessionId = this.activeSessionId;
    }
  },

  async mounted() {
    if (this.activeSessionId) this.sessionId = this.activeSessionId;
    let params = {
      created_after: "2020-01-01 00:00:00",
      type: this.type,
      is_visible: this.is_visible
    };
    const sessionRes = await courseApi.fetchSessions(params);

    const { course_sessions } = sessionRes;

    this.sessionList = course_sessions;
    let studentsLeft = 0;
    this.sessionList.forEach(session => {
      studentsLeft += session.student_left;
    });
    this.studentsLeft = studentsLeft;
  },

  methods: {}
};
</script>
<style lang="css">
.el-radio-button--small .el-radio-button__inner {
  padding: 12px 15px;
  font-size: 12px;
  border-radius: 0;
  height: 38px;
}
</style>
<style scoped>
.item >>> .el-badge__content {
  height: 18px;
  line-height: 16px;
}
</style>
